import React from 'react';
import '../scss/header.scss';

export default function Header() {
    return (
        <header>

            <h1>Tic - Tac - Toe</h1>

        </header>
    );
}